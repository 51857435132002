import React from 'react';
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import './PDFViewer.scss';
import { info } from 'sass';

const DEFAULT_BRANDING_COLOR = "#47c1cc";

const PDFViewer = props => {
  const [color, setColor] = React.useState(DEFAULT_BRANDING_COLOR);
  const [sidQuery, setSidQuery] = React.useState(null);
  const [brandingOption, setBrandingOption] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  // //////////////////////////////////////////
  // Styles

  const triangle1Style = {
    backgroundColor: color,
    opacity: 0.8,
  };
  const triangle2Style = {
    backgroundColor: color,
    opacity: 0.5,
  };
  const triangle3Style = {
    backgroundColor: color,
    opacity: 0.4,
  };

  /* ----------- Processing URL parameters ------------ */
  let searchText = props.history.location.search;
  searchText = searchText.replace('?', '');
  const searchParams = searchText.split('&');
  let newSidQuery = null;
  const searchValues = {};

  const gotoNotFound = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/not-found`
    );
  };

  const gotoRemovedSite = () => {
    window.location.assign(
      `${process.env.REACT_APP_API_CLIENT_BASE_URL}/removed-site`
    );
  };

  if (searchParams.length > 0) {
    searchParams.forEach(sp => {
      const valuePair = sp.split('=');
      searchValues[valuePair[0]] = valuePair[1];
    });
  }

  if (searchValues['sid']) {
    newSidQuery = searchValues['sid'];
  }

  if (!searchValues['params']) {
    gotoNotFound();
  }

  const decodedParams = decodeURIComponent(searchValues['params']);
  let newParams = {};
  let userId = null;
  // let collectionId = null;

  try {
    newParams = JSON.parse(decodedParams);
    // if (searchValues['collectionId']) {
    //   collectionId = searchValues['collectionId'];
    // }

    // if (searchValues['userId']) {
    //   userId = searchValues['userId'];
    // }

    // if (newParams['userId']) {
    //   userId = newParams['userId'];
    // }

    // if (newParams['collectionId']) {
    //   collectionId = newParams['collectionId'];
    // }
  } catch (err) {
    console.log("error: ", err.message);
    gotoNotFound();
  }
  // setPdfUrl(newParams.url);
  /* ------------------------------------------------- */

  const initSettings = async () => {
    setIsLoading(true);
    try {
      setSidQuery(newSidQuery);

      if (!newParams['tenantId']) {
        setIsLoading(false);
        return;
      }

      const collectionAPIUrl = `${process.env.REACT_APP_API_SERVER_BASE_URL}/collections/v1/items?tenantId=${newParams['tenantId']}&branding=true`;
      const configOptions = {
        headers: {
          "Content-Type": "application/json"
        },
        crossdomain: true
      };

      const response = await axios.get(collectionAPIUrl, configOptions);
      if (response.data.data.deletedAt) {
        gotoRemovedSite();
      }
      if (response) {
        if (response.data.data.branding) {
          setBrandingOption(response.data.data.branding);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error.message);
      setIsLoading(false);
      // gotoNotFound();
    }
  }

  React.useEffect(() => {
    initSettings();
  }, []);

  React.useEffect(() => {
    if (brandingOption && brandingOption.color) {
      setColor(brandingOption.color);
    }
  }, [brandingOption]);

  return (
    <div className="pdf-viewer-container relative overflow-hidden">
      {isLoading && (
        <div className="loading-wrapper">
          {/* <img className="cc-logo" src={imgLogo} alt="logo" /> */}
          <BeatLoader size={20} margin={4} color="#36D7B7" />
        </div>
      )}
      {!isLoading && (
        <div className="brand-box relative w-full h-[500px]">
          {/* Custom Background */}
          <div
            className="custom-background absolute w-full h-[120px] top-[-60px] skew-y-[-16deg] overflow-hidden origin-left opacity-90"
            style={{ backgroundColor: color }}
          ></div>

          {/* Triangle 1 */}
          <div
            className="triangle1 absolute w-full h-full skew-y-[-8deg] overflow-hidden origin-left"
            style={triangle1Style}
          ></div>

          {/* Triangle 2 */}
          <div
            className="triangle2 absolute h-[80px] right-[120px] bottom-0 left-0 skew-y-[-8deg] overflow-hidden origin-left"
            style={triangle2Style}
          ></div>

          {/* Triangle 3 */}
          <div
            className="triangle3 absolute h-[100px] right-[40px] bottom-[calc(60%*-0.06)] left-2/4 skew-y-[-8deg] overflow-hidden origin-left"
            style={triangle3Style}
          ></div>
        </div>
      )}

      {/* PDF Container */}
      <div
        className="_df_book"
        height="100%"
        webgl="true"
        backgroundcolor="transparent"
        source={newParams["url"]}
        id="df_manual_book"
      />
    </div>
  );
}

export default PDFViewer;
