import React from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar';

const CTA_STYLES = {
  FULL_WIDTH: "fullWidth",
  BUTTON_ONLY: "buttonOnly",
  CHAT_BUBBLE: "chatBubble",
};

const CtaComponent = ({
  ctaData,
  user,
  enablePersonalization,
  buttonColor,
  buttonHoverColor,
  onClose = () => {},
}) => {
  // Initialization method to merge ctaData with default/prop values.
  const initData = () => ({
    style: ctaData?.options?.style || CTA_STYLES.FULL_WIDTH,
    userName: ctaData?.userName || "",
    message: ctaData?.message || "",
    buttonText: ctaData?.buttonText || "Click here",
    buttonColor: buttonColor || "#1D4ED8",
    buttonHoverColor: buttonHoverColor || "#1E40AF",
    targetUrl: ctaData?.targetUrl || "",
    imageUrl: ctaData?.imageUrl || "",
  });

  // Unpack the final values.
  const {
    style: finalStyle,
    message: finalMessage,
    buttonText: finalButtonText,
    buttonColor: finalButtonColor,
    buttonHoverColor: finalButtonHoverColor,
    targetUrl: finalTargetUrl,
    imageUrl: finalImageUrl,
    userName: finalUserName,
  } = initData();

  return (
    <>
      {/* Sticky Banner */}
      {finalStyle === CTA_STYLES.FULL_WIDTH && (
        <div
          id="marketing-banner"
          className="fixed z-50 flex flex-col md:flex-row justify-between w-[calc(100%-2rem)] p-4 -translate-x-1/2 bg-white border border-gray-100 rounded-lg shadow-lg lg:max-w-7xl left-1/2 bottom-6"
        >
          {/* only enable when there's no finalImageUrl to override AND enablePersonalization is true */}
          {finalImageUrl ? (
            <img src={finalImageUrl} alt="CTA" className="h-16 w-16 object-cover rounded-lg m-2" />
          ) : enablePersonalization ? (
            <Avatar user={user} enablePersonalization={enablePersonalization} />
          ) : null}
          <div className="flex flex-col md:flex-row md:items-center mb-3 md:mb-0">
            <p className="text-base font-normal text-gray-700 whitespace-nowrap flex-grow mr-4">{finalMessage}</p>
          </div>
          <div className="flex items-center">
            <a
              href={finalTargetUrl}
              className="px-5 py-2 mr-2 text-sm font-medium text-white rounded-md focus:ring-4 focus:ring-blue-300"
              style={{
                backgroundColor: finalButtonColor,
                transition: "background-color 0.2s ease-in-out",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = finalButtonHoverColor)}
              onMouseLeave={(e) => (e.target.style.backgroundColor = finalButtonColor)}
            >
              {finalButtonText}
            </a>
            <button
              onClick={onClose}
              type="button"
              className="inline-flex justify-center w-7 h-7 text-gray-400 hover:bg-gray-200 rounded-lg"
            >
              <svg className="w-3 h-3" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                <path stroke="currentColor" strokeWidth="2" d="M1 1l12 12M13 1 1 13" />
              </svg>
              <span className="sr-only">Close banner</span>
            </button>
          </div>
        </div>
      )}

      {/* Chat Bubble CTA */}
      {finalStyle === CTA_STYLES.CHAT_BUBBLE && (
        <div className="fixed bottom-6 left-6 z-50 flex items-center bg-white border border-gray-100 rounded-lg shadow-lg overflow-hidden">
          {/* only enable when there's no finalImageUrl to override AND enablePersonalization is true */}
          {finalImageUrl ? (
            <img src={finalImageUrl} alt="CTA" className="h-16 w-16 object-cover rounded-lg m-2" />
          ) : enablePersonalization ? (
            <Avatar user={user} enablePersonalization={enablePersonalization} />
          ) : null}
          <div className="p-4 flex items-center space-x-4">
            <p className="text-base font-normal text-gray-700 whitespace-nowrap flex-grow">{finalMessage}</p>
            <a
              href={finalTargetUrl}
              className="inline-block px-5 py-2 text-sm font-medium text-white rounded-md focus:ring-4 focus:ring-blue-300"
              style={{
                backgroundColor: finalButtonColor,
                transition: "background-color 0.2s ease-in-out",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = finalButtonHoverColor)}
              onMouseLeave={(e) => (e.target.style.backgroundColor = finalButtonColor)}
            >
              {finalButtonText}
            </a>
          </div>
        </div>
      )}

      {/* Button Only CTA – using the full-width container with centered content */}
      {finalStyle === CTA_STYLES.BUTTON_ONLY && (
        <div
          id="cta-button-only"
          className="fixed z-50 flex justify-center items-center w-[calc(100%-2rem)] p-4 -translate-x-1/2 bg-white border border-gray-100 rounded-lg shadow-lg lg:max-w-7xl left-1/2 bottom-6"
        >
          <a
            href={finalTargetUrl}
            className="px-5 py-2 text-sm font-medium text-white rounded-md focus:ring-4 focus:ring-blue-300"
            style={{
              backgroundColor: finalButtonColor,
              transition: "background-color 0.2s ease-in-out",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = finalButtonHoverColor)}
            onMouseLeave={(e) => (e.target.style.backgroundColor = finalButtonColor)}
          >
            {finalButtonText}
          </a>
        </div>
      )}
    </>
  );
};

CtaComponent.propTypes = {
  ctaData: PropTypes.shape({
    options: PropTypes.shape({
      style: PropTypes.string,
    }),
    userName: PropTypes.string,
    message: PropTypes.string,
    targetUrl: PropTypes.string,
    buttonText: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
  user: PropTypes.object,
  enablePersonalization: PropTypes.bool,
  buttonColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  onClose: PropTypes.func,
};

export default CtaComponent;